<template>
    <div class="d-flex gap-3 flex-column">
        <h1 class="fw-bold">User Management</h1>
        <span>Tatsing Group > User Management</span>
        <div class="shadow-sm pt-4 w-100" style="border-radius: 0.75rem;" v-if="$store.getters['get_perm']('viewUsers')">
            <div class="px-4 d-flex justify-content-between flex-wrap mb-2">
                <div class="d-flex flex-wrap justify-content-end gap-4 w-100" v-if="$store.getters['get_perm']('storeUser') || $store.getters['get_perm']('updateUser')">
                  <a href="#user_options" class="text-decoration-none">
                      <div class="d-flex gap-4 align-items-center px-3">
                          <button @click="navigate" class="btn text-white bg-theme px-4" >
                            <i class="bi bi-plus"></i>
                            Create Account
                          </button>
                      </div>
                  </a>
                </div>
                <div id="user_table" class="d-flex flex-wrap justify-content-between w-100 pt-3">
                    <h5 class="w-100 text-center fw-bold" :class="{'text-success': deleteMsg.toLowerCase().includes('success'),
                    'text-danger': !deleteMsg.toLowerCase().includes('success') }" v-show="deleteMsg">
                        User - {{ deleteMsg }}
                    </h5>
                    <div>
                        <span>Show</span>
                        <select v-model="perPage" name="perPage" id="perPage" class="p-1 mx-2 rounded border" style="width: 75px;">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                        <span>Entries</span>
                    </div>
                    <div>
                        <input v-model="search" type="text" name="search" class="w-100 border rounded py-1 px-2" placeholder="Search">
                    </div>
                </div>
            </div>
            <div class="overflow-auto p-3 position-relative">
                <Preloader class="position-absolute w-100 h-100" v-show="isLoading" />
                <table class="table table-hover w-100">
                    <thead class="border-bottom border-1 text-light">
                        <tr style="font-size: small;">
                            <th scope="col" class="py-2" style="width: 25px;"></th>
                            <th scope="col" class="py-2 bg-secondary-theme text-center position-relative" @click="sortBy('full_name')">Name</th>
                            <th scope="col" class="py-2 bg-secondary-theme text-center" @click="sortBy('email')">Email</th>
                            <th scope="col" class="py-2 bg-secondary-theme text-center" @click="sortBy('username')">Username</th>
                            <th scope="col" class="py-2 bg-secondary-theme text-center">Job Title</th>
                            <th scope="col" class="py-2 bg-secondary-theme text-center">Department</th>
                            <th scope="col" class="py-2" style="width: 25px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="font-size: small;" v-for="data in $store.state.user.users_data.data" :key="data">
                            <td scope="row"></td>
                            <td class="fw-bold text-theme">{{data.full_name}}</td>
                            <td class="overflow-hidden" stye="width: 258.6px;">{{data.email}}</td>
                            <td class="overflow-hidden" stye="width: 258.6px;">{{data.username}}</td>
                            <td class="overflow-hidden" stye="width: 258.6px;">{{data.job_title}}</td>
                            <td class="overflow-hidden" stye="width: 258.6px;">{{data.department_name}}</td>
                            <td>
                                <div class="d-flex">
                                    <button type="button" class="bg-transparent border-0" @click="edit(data.id)" v-if="$store.getters['get_perm']('updateUser')"><i class="bi bi-pencil-square text-secondary-theme"></i></button>
                                    <button type="button" class="bg-transparent border-0" @click="deleteModal(data.id)" v-if="$store.getters['get_perm']('deleteUser')"><i class="bi bi-trash text-theme"></i></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <span>Browsing {{ $store.state.user.from }} - {{ $store.state.user.to }} of {{ $store.state.user.total }} Entries</span>
                    <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" :maxVisibleButtons="4" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
        
        <div id="user_options" class="d-flex flex-column gap-2 mt-4 pt-4 position-relative" v-if="$store.getters['get_perm']('storeUser') || $store.getters['get_perm']('updateUser')">
        <Preloader class="position-absolute w-100 h-100" v-show="isLoading" />
        <h2 class="mx-auto" style="font-size: large;">{{title}} User</h2>
          <strong class="text-danger ms-auto">* - Required</strong>
          <form @submit.prevent="createOrUpdate">
              <div class="card py-3 px-4">
                  <div class="pb-2 fs-4 fw-bold text-center" :class="{'text-success': update_createMsg.toLowerCase().includes('success'),'text-danger': !update_createMsg.toLowerCase().includes('success')}"
                  v-show="message">
                      {{ update_createMsg }}
                  </div>
                  <div class="d-flex align-items-center flex-column flex-md-row w-100 gap-4">
                      <div class="w-100 d-flex flex-column">
                          <label ref="full_name" for="full_name">Full Name<strong class="text-danger">*</strong></label>
                          <input id="full_name" v-model="user_data.full_name" type="text"
                            placeholder="Name" class="mb-3 w-100 border rounded py-2 px-4" required autocomplete="nope">
                          <div class="text-danger" v-show="errors.full_name">
                              <ul>
                                  <li v-for="item in errors.full_name" :key="item">
                                      {{ item }}
                                  </li>
                              </ul>
                          </div>
                          
                          <label ref="username" for="username">Username<strong class="text-danger">*</strong></label>
                          <input id="username" v-model="user_data.username" type="text"
                            placeholder="Username" class="mb-3 w-100 border rounded py-2 px-4" required autocomplete="nope">
                          <div class="text-danger align-middle" v-show="errors.username">
                              <ul>
                                  <li v-for="item in errors.username" :key="item">
                                      {{ item }}
                                  </li>
                              </ul>
                          </div>
                          
                          <label ref="email" for="email">Email<strong class="text-danger">*</strong></label>
                          <input id="email" v-model="user_data.email" type="email"
                            placeholder="Email" class="mb-3 w-100 border rounded py-2 px-4" required autocomplete="nope">
                          <div class="text-danger" v-show="errors.email">
                              <ul>
                                  <li v-for="item in errors.email" :key="item">
                                      {{ item }}
                                  </li>
                              </ul>
                          </div>
                          
                          <label ref="password" for="password">Password<strong class="text-danger" v-show="pass_req">*</strong></label>
                          <div class="d-flex position-relative w-75">
                              <span v-show="hasGenerated">Generated Password: <strong class="text-theme">{{user_data.password}}</strong></span>
                          </div>
                          <div class="d-flex justify-content-end gap-3 mb-3 align-items-center">
                              <input id="password" v-model="user_data.password" type="password"
                              placeholder="Password" class="w-100 border rounded py-2 px-4" :required="pass_req">

                              <button type="button" class="bg-theme text-light rounded p-2" @click="generate">Generate</button>
                          </div>
                          <div class="text-danger" v-show="errors.password">
                              <ul>
                                  <li v-for="item in errors.password" :key="item">
                                      {{ item }}
                                  </li>
                              </ul>
                          </div>
                          
                          <label ref="password_confirmation" for="password_confirmation">Password Confirmation<strong class="text-danger" v-show="pass_req">*</strong></label>
                          <input id="password_confirmation" v-model="user_data.password_confirmation" type="password"
                            placeholder="Confirm Password" class="mb-3 w-100 border rounded py-2 px-4" :required="pass_req">
                          <div class="text-danger" v-show="errors.password_confirmation">
                              <ul>
                                  <li>
                                      {{ errors.password_confirmation }}
                                  </li>
                              </ul>
                          </div>

                          <label ref="role" for="role">Role<strong class="text-danger">*</strong></label>
                          <select id="role" v-model="user_data.role_name" class="mb-3 w-100 border rounded py-2 px-4 text-muted bg-transparent" required>
                              <option value='' selected disabled>Select Role</option>
                              <option value="Admin">Admin</option>
                              <option value="Staff">Staff</option>
                          </select>
                          <div class="text-danger" v-show="errors.role">
                              <ul>
                                  <li v-for="item in errors.role" :key="item">
                                      {{ item }}
                                  </li>
                              </ul>
                          </div>

                          <label for="job_title">Job Title</label>
                          <input id="job_title" v-model="user_data.job_title" type="text"
                            placeholder="Job Title" class="mb-3 w-100 border rounded py-2 px-4">
                          <div class="text-danger" v-show="errors.job_title">
                              <ul>
                                  <li>
                                      {{ errors.job_title }}
                                  </li>
                              </ul>
                          </div>

                          <div class="w-100 d-flex flex-column gap-2 card rounded-top shadow-sm py-3 px-5">
                              <div class="d-flex flex-column mx-auto">
                                  <span class="fw-bold text-center">Profile Photo</span>
                                  <span class="text-center">{{ photo_properties.name }}</span>
                              </div>
                              <img :src="file_data ? file_data : decoded_photo" v-show="(file_data || decoded_photo) && !notanImage" alt="profile-photo"
                              class="mx-auto rounded-circle shadow-sm" style="width: 125px; height:125px">

                              <span v-if="!user_data.profile_photo" :class="{'d-none': file_data && !user_data.profile_photo}" class="mx-auto rounded-circle shadow-sm bg-muted text-center fw-bold text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                </svg>
                              </span>

                              <span v-show="notanImage" class="mx-auto rounded-circle shadow-sm bg-danger text-center fw-bold text-white py-4"
                              style="width: 125px; height:125px;">Please pick a valid Image</span>
                              
                              <button type="button" class="btn bg-theme text-white py-2 px-2 mx-auto rounded border-0"
                                  style="width: 150px;" @click="photo_edit_clicked">Edit Picture</button>
                              <input ref="upload" type="file" accept="image/*" class="d-none" @change="pick_photo">
                          </div>
                          <div class="text-danger" v-show="errors.profile_photo">
                              <ul>
                                  <li>
                                      {{ errors.profile_photo }}
                                  </li>
                              </ul>
                          </div>

                          <div class="bg-light d-flex flex-wrap gap-3 p-3 mb-3 shadow-sm border rounded-bottom">
                              <h2 style="font-size: large;">Permission List</h2>
                              <div class="d-flex ms-auto align-items-center gap-2">
                                  <label for="all">Grant All</label>
                                  <input @change="grantAll" id="all" type="checkbox" style="height: 20px; width: 20px;">
                              </div>

                              <div class="px-2 w-100">   
                                  <h2 style="font-size: large;">User</h2>
                                  <div class="w-100 d-flex flex-wrap gap-4 p-4 border">
                                      <div class="d-flex ms-auto align-items-center gap-2" v-for="ability in abilities.user" v-bind:key="ability">
                                          <label :for="ability">{{ability}}</label>
                                          <input v-model="user_data.abilities" :id="ability" :value="ability"
                                          type="checkbox" style="height: 20px; width: 20px;">
                                      </div>
                                  </div>
                              </div> 
                              <div class="px-2 w-100">   
                                  <h2 style="font-size: large;">Logistic</h2>
                                  <div class="w-100 d-flex flex-wrap gap-4 p-4 border">
                                      <div class="d-flex ms-auto align-items-center gap-2" v-for="ability in abilities.logistic" v-bind:key="ability">
                                          <label :for="ability">{{ability}}</label>
                                          <input v-model="user_data.abilities" :id="ability" :value="ability"
                                          type="checkbox" style="height: 20px; width: 20px;">
                                      </div>
                                  </div>
                              </div> 
                              <div class="px-2 w-100">   
                                  <h2 style="font-size: large;">Event</h2>
                                  <div class="w-100 d-flex flex-wrap gap-4 p-4 border">
                                      <div class="d-flex ms-auto align-items-center gap-2" v-for="ability in abilities.event" v-bind:key="ability">
                                          <label :for="ability">{{ability}}</label>
                                          <input v-model="user_data.abilities" :id="ability" :value="ability"
                                          type="checkbox" style="height: 20px; width: 20px;">
                                      </div>
                                  </div>
                              </div> 

                              <div class="w-100 text-end">
                                  <button type="button" class="bg-secondary-theme text-light border-0 p-2 rounded shadow-sm align-items-center"
                                  @click="showLegend = !showLegend">
                                      Legend
                                      <i class="bi bi-chevron-down" v-show="!showLegend"></i>
                                      <i class="bi bi-chevron-up" v-show="showLegend"></i>
                                  </button>
                                  <div v-show="showLegend" class="text-start px-4">
                                      <div class="d-flex flex-column gap-3">
                                          <span>Grant All = Permit user from accessing all Permission.</span>
                                          <span>*:view = Permit user from accessing data Table.</span>
                                          <span>*:store = Permit user from accessing data Creation.</span>
                                          <span>user:store-partial = Permit user from accessing "Create Staff" page.</span>
                                          <span>*:update = Permit user to update <strong>ANY</strong> data.</span>
                                          <span>user:update-self = Permit user from updating <strong>ONLY</strong> his/her personal data.</span>
                                          <span>*:delete = Permit user from deleting <strong>ANY</strong> data.</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="text-danger" v-show="errors.abilities">
                              <ul v-for="error in errors.abilities" :key="error">
                                  <li>
                                      {{ error }}
                                  </li>
                              </ul>
                          </div>
                          
                      </div>
                  </div>
              </div>
              <div class="card py-3 px-4">
                  <div class="ms-auto">
                      <button type="submit" class="bg-theme text-white py-2 px-5 rounded border-0" :disabled="isLoading">Save</button>
                  </div>
              </div>
          </form>
        </div>
        <Modal>
            <template v-slot:head>
                <h5 class="modal-title">Delete User</h5>
                <button type="button" class="close" @click="closeModal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </template>
            <template v-slot:main>
                <span>Do you want to delete <strong>{{ to_deleteUser.full_name }}</strong>'s account?</span>
                <ul>
                    <li>Email: <strong>{{ to_deleteUser.email }}</strong></li>
                    <li>Username: <strong>{{ to_deleteUser.username }}</strong></li>
                </ul>
            </template>
            <template v-slot:footer>
                <button type="button" class="bg-secondary-theme text-white p-1 rounded" style="width:75px;" @click="closeModal">Close</button>
                <button type="button" class="bg-theme text-white p-1 rounded" style="width:75px;" @click="del(to_deleteUser.id)">Delete</button>
            </template>
        </Modal>
      </div>
</template>

<style scoped src='../../../assets/css/inner.css'></style>

<script>
import Pagination from "../../../components/Pagination.vue";
import Preloader from "../../../components/Preloader.vue";
import Modal from "../../../components/Modal.vue";
// import Sorting from "../../../../components/Sorting.vue";
import { createNamespacedHelpers } from "vuex";
createNamespacedHelpers('tablecontroller', 'user')

export default {
  name: 'Dashboard',
  components: {
    Pagination,
    Preloader,
    Modal,
    // Sorting
  },
  data() {
    return {
        table_option: {},
        user_data: {},
        abilities: {
            user: ['user:view', 'user:store', 'user:store-partial', 'user:show', 'user:update', 'user:update-self', 'user:delete'],
            career: ['career:view', 'career:show', 'career:store', 'career:update', 'career:delete'],
            department: ['department:view', 'department:show', 'department:store', 'department:update', 'department:delete'],
            role: ['role:view', 'role:show', 'role:store', 'role:update', 'role:delete'],
            logistic: ['logistic:view', 'logistic:show', 'logistic:store', 'logistic:update', 'logistic:delete'],
            event: ['event:view', 'event:show', 'event:store', 'event:update', 'event:delete'],
        },
        to_editPhoto: false,
        notanImage: false,
        file_data: '',
        photo_properties: {
            name: ''
        },
        decoded_photo: '',
        pass_req: true,
        hasGenerated: false,
        hasGrantAll: false,
        showLegend: false,
        title: 'Create',
        method: '',
        to_deleteUser: {},
        deleteMsg: '',
        update_createMsg: ''
    }
  },
  beforeMount() {
      if(!this.$store.getters['get_perm']('viewUsers')) this.$router.push('/dashboard')
  },
  mounted() {
    if(this.$store.getters['get_perm']('viewUsers')) {
        if(this.$store.state.user.user_data.username) this.$store.commit('user/reset_user_data')
        if(this.errors) this.$store.commit('user/reset_errors')
        if(this.message) this.$store.commit('user/reset_message')

            Object.assign(this.table_option, {
                search: this.$store.state.tablecontroller.search,
                sort_by: this.$store.state.tablecontroller.sort_by,
                sort_dir: this.$store.state.tablecontroller.sort_dir,
                perPage: this.$store.state.tablecontroller.perPage,
                currentPage: this.$store.state.user.currentPage
            })
            this.$store.dispatch('user/getUsers', this.table_option);

        Object.assign(this.user_data, this.$store.state.user.user_data)
        Object.assign(this.user_data, { role_name: 'Admin'})
    }
  },
  methods: {
    onPageChange(page) {
      this.table_option.currentPage = page;
      this.$store.dispatch('user/getUsers', this.table_option);
    },
    sortBy(value) {
      this.table_option.sort_by = value
      this.table_option.sort_dir = this.table_option.sort_by === value 
          ? (this.table_option.sort_dir === 'desc' ? 'asc' : 'desc') 
          : 'desc'
      this.$store.dispatch('user/getUsers', this.table_option);
    },
    generate() {
        const keys = {
            upperCase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            lowerCase: 'abcdefghijklmnopqrstuvwxyz',
            number: '0123456789',
            symbol: '~!@#$%^&*()_+,.?<>'
        }
        const getKey = [
            function upperCase() {
                return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
            },
            function lowerCase() {
                return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
            },
            function number() {
                return keys.number[Math.floor(Math.random() * keys.number.length)];
            },
            function symbol() {
                return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
            }
        ]
        const max = 12
        var generated = ''

        while (max > generated.length) {
            let keyToAdd = getKey[Math.floor(Math.random() * getKey.length)];
            generated += keyToAdd();
        }
        
        const regexl = /[a-z]/;
        const regexc = /[A-Z]/;
        const regexn = /[0-9]/;

        if(!regexl.test(generated) || !regexc.test(generated) || !regexn.test(generated)) {
            this.generate()
        }
        else {
            this.user_data.password = generated
            this.user_data.password_confirmation = generated
            this.hasGenerated = true
        }
        
    },
    photo_edit_clicked() {
        this.$refs.upload.click()
        this.to_editPhoto = true;
    },
    pick_photo(event) {
        const files = event.target.files
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
            this.file_data = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        this.photo_properties = files[0]
        if(!(this.photo_properties['type'].split('/')[0] === 'image')) {
            this.notanImage = true
        }
        else {
            this.notanImage = false
        }
    },
    async upload() {
        var formData = new FormData();
        formData.append('file_data', this.photo_properties)
        await this.$store.dispatch("user/upload", formData)
    },
    grantAll() {
        this.hasGrantAll = ! this.hasGrantAll

        if(this.hasGrantAll) {
            this.user_data.abilities = []
            for(var i = 0; i < this.abilities.user.length; i++) {
                if(!(this.abilities.user[i].toLowerCase().includes("self")
                    || this.abilities.user[i].toLowerCase().includes("partial")))
                {
                    this.user_data.abilities.push(this.abilities.user[i])
                }
                document.getElementById(this.abilities.user[i]).disabled = true
            }
            for(var l = 0; l < this.abilities.logistic.length; l++) {
                if(!(this.abilities.logistic[l].toLowerCase().includes("self")
                    || this.abilities.logistic[l].toLowerCase().includes("partial")))
                {
                    this.user_data.abilities.push(this.abilities.logistic[l])
                }
                document.getElementById(this.abilities.logistic[l]).disabled = true
            }
            for(var e = 0; e < this.abilities.event.length; e++) {
                if(!(this.abilities.event[e].toLowerCase().includes("self")
                    || this.abilities.event[e].toLowerCase().includes("partial")))
                {
                    this.user_data.abilities.push(this.abilities.event[e])
                }
                document.getElementById(this.abilities.event[e]).disabled = true
            }
        }
        else {
            this.user_data.abilities = []
            for(var i1 = 0; i1 < this.abilities.user.length; i1++) {
                document.getElementById(this.abilities.user[i1]).disabled = false
            }
            for(var l1 = 0; l1 < this.abilities.logistic.length; l1++) {
                document.getElementById(this.abilities.logistic[l1]).disabled = false
            }
            for(var e1 = 0; e1 < this.abilities.event.length; e1++) {
                document.getElementById(this.abilities.event[e1]).disabled = false
            }
        }
        
    },
    async edit(id) {
      this.title = 'Update'
      this.method = 'patch'
      this.pass_req = false

      const obj = Object.values(this.$store.state.user.users_data.data)

        const found_obj = obj.find((key) => key.id === id)

        Object.assign(this.user_data, found_obj)

        if(this.user_data.profile_photo) {
            this.$store.dispatch('user/getPhoto', { id: this.user_data.id, photo_name: this.user_data.profile_photo }).then(() => {
                this.decoded_photo = this.$store.state.user.profile_photo
            })
        }

        document.getElementById('user_options').scrollIntoView()
    },
    closeModal() {
        document.getElementById('modal-container').style.display = "none";
    },
    async deleteModal(id) {
        document.getElementById('modal-container').style.display = "block";

        const obj = Object.values(this.$store.state.user.users_data.data)

        const found_obj = obj.find((key) => key.id === id)

        Object.assign(this.to_deleteUser, found_obj)
    },
    async del(id) {
      Object.assign(this.user_data, { _method: 'DELETE' })
      await this.$store.dispatch('user/deleteUser', id).then(() => {
        this.deleteMsg = this.message
        this.closeModal()
          
        this.$store.dispatch('user/getUsers', this.table_option);
        
        document.getElementById('user_table').scrollIntoView()
      })
    },
    async createOrUpdate() {
      this.isLoading = true

      if(this.user_data.role_name === 'Admin') this.user_data.role = '2'
      if(this.user_data.role_name === 'Staff') this.user_data.role = '3'

      if(this.photo_properties['name']){
        await this.upload().then(() => {
          Promise.resolve(
            Object.assign(this.user_data, { profile_photo: this.$store.state.user.profile_photo })
            ).then(() => {
              if(this.method === 'patch') {
                Object.assign(this.user_data, { _method: 'PATCH' })
                this.$store.dispatch('user/updateUser', this.user_data).then(() => {
                    this.update_createMsg = this.message
                    this.$store.dispatch('user/getUsers', this.table_option);
                    document.getElementById('user_options').scrollIntoView()
                })
                if(this.errors.full_name) {
                    this.$refs.full_name.scrollIntoView()
                    return
                }
                if(this.errors.username) {
                    this.$refs.username.scrollIntoView()
                    return
                }
                if(this.errors.email) {
                    this.$refs.email.scrollIntoView()
                    return
                }
                if(this.errors.password) {
                    this.$refs.password.scrollIntoView()
                    return
                }
                if(this.errors.password_confirmation) {
                    this.$refs.password_confirmation.scrollIntoView()
                    return
                }
                if(this.errors.role) {
                    this.$refs.role.scrollIntoView()
                    return
                }
              }
              else {
                Promise.resolve(this.$store.dispatch('user/createUser', this.user_data)).then(() => {
                    if(this.errors.full_name) {
                        this.$refs.full_name.scrollIntoView()
                        return
                    }
                    if(this.errors.username) {
                        this.$refs.username.scrollIntoView()
                        return
                    }
                    if(this.errors.email) {
                        this.$refs.email.scrollIntoView()
                        return
                    }
                    if(this.errors.password) {
                        this.$refs.password.scrollIntoView()
                        return
                    }
                    if(this.errors.password_confirmation) {
                        this.$refs.password_confirmation.scrollIntoView()
                        return
                    }
                    if(this.errors.role) {
                        this.$refs.role.scrollIntoView()
                        return
                    }
                    this.update_createMsg = this.message
                    if(this.message.toLowerCase().includes('success')) {
                        Object.assign(this.user_data, this.$store.state.user.user_data)
                        this.$store.dispatch('user/getUsers', this.table_option);
                        document.getElementById('user_options').scrollIntoView()
                    }
                })
              }
            })
        })
        return
      }

      if(this.method === 'patch') {
        Object.assign(this.user_data, { _method: 'PATCH' })
        this.$store.dispatch('user/updateUser', this.user_data).then(() => {
            this.update_createMsg = this.message
            this.$store.dispatch('user/getUsers', this.table_option);
            document.getElementById('user_options').scrollIntoView()
        })

        if(this.errors.full_name) {
            this.$refs.full_name.scrollIntoView()
            return
        }
        if(this.errors.username) {
            this.$refs.username.scrollIntoView()
            return
        }
        if(this.errors.email) {
            this.$refs.email.scrollIntoView()
            return
        }
        if(this.errors.password) {
            this.$refs.password.scrollIntoView()
            return
        }
        if(this.errors.password_confirmation) {
            this.$refs.password_confirmation.scrollIntoView()
            return
        }
        if(this.errors.role) {
            this.$refs.role.scrollIntoView()
            return
        }
      }
      else {
        await this.$store.dispatch('user/createUser', this.user_data).then(() => {
            if(this.errors.full_name) {
                this.$refs.full_name.scrollIntoView()
                return
            }
            if(this.errors.username) {
                this.$refs.username.scrollIntoView()
                return
            }
            if(this.errors.email) {
                this.$refs.email.scrollIntoView()
                return
            }
            if(this.errors.password) {
                this.$refs.password.scrollIntoView()
                return
            }
            if(this.errors.password_confirmation) {
                this.$refs.password_confirmation.scrollIntoView()
                return
            }
            if(this.errors.role) {
                this.$refs.role.scrollIntoView()
                return
            }
            this.update_createMsg = this.message
            if(this.message.toLowerCase().includes('success')) {
                Object.assign(this.user_data, this.$store.state.user.user_data)
                this.$store.dispatch('user/getUsers', this.table_option);
                document.getElementById('user_options').scrollIntoView()
            }
        })
        return
      }
      
    }
  },
  computed: {
    search: {
      get() {
        return this.table_option.search ?  this.table_option.search : this.$store.state.tablecontroller.search;
      },
      set(value) {
        this.table_option.search = value
        this.table_option.currentPage = 1
        this.$store.dispatch('user/getUsers', this.table_option);
      },
    },
    sort_by: {
      get() {
        return this.table_option.sort_by ?  this.table_option.sort_by : this.$store.state.tablecontroller.sort_by;
      }
    },
    sort_direction: {
      get() {
        return this.table_option.sort_dir ?  this.table_option.sort_dir : this.$store.state.tablecontroller.sort_dir;
      },
      set(value) {
        this.table_option.sort_dir = value
        this.$store.dispatch('user/getUsers', this.table_option);
      },
    },
    perPage: {
      get() {
        return this.table_option.perPage ?  parseInt(this.table_option.perPage) : this.$store.state.tablecontroller.perPage;
      },
      set(value) {
        this.table_option.perPage = value
        this.$store.dispatch('user/getUsers', this.table_option);
      }
    },
    totalPages: {
        get() {
            return this.$store.state.user.totalPages
        }
    },
    currentPage: {
        get() {
            return this.$store.state.user.currentPage
        }
    },
    errors: {
        get() {
            return this.$store.state.user.errors.errors
        }
    },
    message: {
        get() {
            return this.$store.state.user.message
        },
        set(value) {
          return value
        }
    },
    isLoading: {
        get() {
            return this.$store.state.user.isLoading
        },
        set(value) {
          return value
        }
    },
  },
}
</script>